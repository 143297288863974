// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";

// Import assets
import '../assets/styles/components/right-menu.scss';

// Import components
import {getPropsFromState} from "../redux/mapStateToProps";
import {ChangeLanguage} from "../redux/actions";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";



const RightMenu = memo((props) => {
    const {className, staticTexts, toggleMobileMenu,languages,selectedLanguage} = props;

    /*todo Ջնջել չօգտագործվող props-երը ու mapStateToProps*/
    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <nav className='mobile-nav'>
                <NavLink to={getUrlWithLocal('/home')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_home}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_about}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/work')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_work}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/partners')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_staff}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/services')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_services}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/contacts')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_contact}</div>
                </NavLink>
                <div className="language-items">
                    {
                        languages?.length && languages?.map((item) => {
                            return <div key={item.id}
                                           onClick={() => {
                                               props.ChangeLanguage(item)
                                           }}
                                           className={'language-dropdown-item'}>
                                    <div className={`selected-language 
                                    ${selectedLanguage?.code === item.code ? 'active-language' : ''}`}>
                                       {item?.name}
                                    </div>
                                </div>
                        })
                    }
                </div>
            </nav>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
