// import packages
import React from 'react';
import isEmail from "validator/es/lib/isEmail";
import {useState} from "react";

//Import Components
import {InputGroup} from "../uiElements/inputGroup";

//Import assets
import "../../assets/styles/homepage/contactModal.scss"

// Import utils
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {Checkbox, Modal, Radio} from "antd";
import {FlagIcon, ModalDownloadIcon} from "../../assets/images";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {SendProffer} from "../../redux/actions";

const inputData = {
    name:'',
    residentialAddress:'',
    billingAddress:'',
    tss:'',
    bankAccountNumber:'',
    director:'',
    email:'',
    phone:'',
    deviceAvailability:'AVAILABLE',
    optionToPurchase:'RIGHT_OF_OWNERSHIP',
}

function ContactModal(props){
    const {modalOpen,toggleModalOpen,contacts,staticTexts} = props
    const [fieldsData, setFieldsData] = useState(inputData)
    const [checkout, setCheckout] = useState(false)
    const [errors, setErrors] = useState({
        name:false,
        residentialAddress:false,
        billingAddress:false,
        tss:false,
        bankAccountNumber:false,
        director:false,
        email:false,
        phone:false,
        deviceAvailability:false,
        optionToPurchase:false,
        checkout:false
    })
    const onChangeFirst = (e) => {
        setFieldsData({
            ...fieldsData,
            deviceAvailability:e.target.value
        })
    };
    const onChangeSecond = (e) => {
        setFieldsData({
            ...fieldsData,
            optionToPurchase:e.target.value
        })
    };

    const onChangeChecked = (e) => {
        setCheckout(!fieldsData.checkout)
    };

    function closeModal(){
       toggleModalOpen()
    }
    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email))
            ) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        if(!checkout){
            setErrors({
                ...errors,
                checkout: true
            })
            result = false
        }
        return result;
    }

    function Send() {
        if(validate()){
            props.SendProffer(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData(inputData)
                setCheckout(false)
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        if (name === "bankAccountNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function downloadFile(pdf) {
        console.log(pdf,'contacts?.pdf')
        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = 'file';
                        a.click();
                    });
                });
        }
    }

    return <Modal centered
                  title={<div className={'modal-title'}>
                      <div className={'modal-flag'}>
                          <FlagIcon/>
                      </div>
                      <span>{staticTexts?.contactModal_subtitle}</span>
                  </div>}
                  visible={modalOpen}
                  okText={staticTexts?.contactModal_ok_btn}
                  cancelText={staticTexts?.contactModal_cancel_btn}
                  onOk={Send}
                  onCancel={closeModal}>
        <div className={'body-wrapper'}>
            <div className={'inputs-wrapper'}>
                <div className={'left-inputs-block'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_name}
                        value={fieldsData.name}
                        error={errors.name}
                        name={'name'}
                        placeholder={staticTexts?.contactModal_placeholder_name}
                        maxLength={100}
                        onChange={getInputValues}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_resAddress}
                        value={fieldsData.billingAddress}
                        error={errors.billingAddress}
                        name={'billingAddress'}
                        placeholder={staticTexts?.contactModal_placeholder_resAddress}
                        maxLength={100}
                        onChange={getInputValues}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_bank}
                        value={fieldsData.bankAccountNumber}
                        error={errors.bankAccountNumber}
                        name={'bankAccountNumber'}
                        placeholder={staticTexts?.contactModal_placeholder_bank}
                        maxLength={100}
                        onChange={getInputValues}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_fullName}
                        value={fieldsData.director}
                        error={errors.director}
                        name={'director'}
                        placeholder={staticTexts?.contactModal_placeholder_fullName}
                        maxLength={100}
                        onChange={getInputValues}/>
                </div>
                <div className={'right-inputs-block'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_address}
                        value={fieldsData.residentialAddress}
                        error={errors.residentialAddress}
                        name={'residentialAddress'}
                        placeholder={staticTexts?.contactModal_placeholder_address}
                        maxLength={100}
                        onChange={getInputValues}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_tss}
                        value={fieldsData.tss}
                        error={errors.tss}
                        name={'tss'}
                        placeholder={staticTexts?.contactModal_placeholder_tss}
                        maxLength={100}
                        onChange={getInputValues}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_email}
                        value={fieldsData.email}
                        error={errors.email}
                        name={'email'}
                        placeholder={staticTexts?.contactModal_placeholder_email}
                        maxLength={100}
                        onChange={getInputValues}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        label={staticTexts?.contactModal_label_phone}
                        value={fieldsData.phone}
                        error={errors.phone}
                        name={'phone'}
                        placeholder={staticTexts?.contactModal_placeholder_phone}
                        maxLength={100}
                        onChange={getInputValues}/>
                </div>
            </div>
            <div className={'radio-btn-block'}>
                <div className={'radio-btn-title'}>
                    {staticTexts?.contactModal_radio_btn_title}
                </div>
                <div className={'top-radio-block'}>
                    <Radio.Group onChange={onChangeFirst} value={fieldsData.deviceAvailability}>
                        <Radio value={'AVAILABLE'}>{staticTexts?.contactModal_radio_btn_text1}</Radio>
                        <Radio value={'NEW_AVAILABLE'}>{staticTexts?.contactModal_radio_btn_text2}</Radio>
                    </Radio.Group>
                </div>
                <div className={'radio-btn-secTitle'}>
                    {staticTexts?.contactModal_radio2_btn_title}
                </div>
                <div className={'bottom-radio-block'}>
                    <Radio.Group onChange={onChangeSecond} value={fieldsData.optionToPurchase}>
                        <Radio value={'RIGHT_OF_OWNERSHIP'}>{staticTexts?.contactModal_radio2_btn_text1}</Radio>
                        <Radio value={'RIGHT_TO_USE'}>{staticTexts?.contactModal_radio2_btn_text2}</Radio>
                    </Radio.Group>
                </div>
            </div>
            <div className={'bodes-footer'}>
                <div className={`checkin-block ${errors.checkout ? 'checkoutError' : ''}`}>
                    <Checkbox onChange={onChangeChecked} checked={checkout}>
                        {staticTexts?.contactModal_checkout_text}
                    </Checkbox>
                </div>
                <div className={'download-block'} onClick={() => downloadFile(contacts?.pdf)}>
                    <ModalDownloadIcon/> <span>{staticTexts?.contactModal_download_text}</span>
                </div>
            </div>
        </div>
    </Modal>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'contacts',
    ])
};

const mapDispatchToProps = {
    SendProffer
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactModal)