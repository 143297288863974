// import packages
import React from "react";
import {connect} from "react-redux";

//Import styles
import "../../assets/styles/homepage/project.scss";
import {DashIcon, ProjectBgIcon, SliderLinkIcon} from "../../assets/images";

//Import Components
import {Tabs} from 'antd';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

function Project({projects, staticTexts}) {
    return <div className={'homepage_projects-wrapper'}>
        <div className={'homepage_projects-block'}>
            <div className="homepage_projects-content">
                <div className={'text-content'}>
                    <p>{staticTexts?.homepage_projects_subtitle}</p>
                    <div className="title">{staticTexts?.homepage_projects_title}</div>
                </div>
            </div>

            <div className={'projects-block'}>
                <Tabs
                    tabPosition={'right'}
                    defaultActiveKey="1"
                    items={projects?.length && projects?.map((item, index) => {
                        return {
                            label: <div className={'project'}>
                                <div className={'project-title'}>
                                    {item?.title}
                                </div>
                                <div className={'project-description'}>
                                    {item?.description}
                                </div>
                                <div className={'options-wrapper'}>
                                    {
                                        item?.options.length &&  item?.options.map(options => {
                                            return <div className={'options'}>
                                                <DashIcon/> {options}
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    <a href={item?.link} target={'_blank'} className={'project-more-news'}>
                                        {staticTexts?.homepage_more_info_btn} <SliderLinkIcon/>
                                    </a>
                                }
                            </div>,
                            key: item?.id,
                            children: <div className={'img-wrapper'}>
                                <div className={'projectBg'}>
                                    <ProjectBgIcon/>
                                </div>
                                <span className={'img'}>
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}
                                     className="about-img"/>
                            </span>
                            </div>,
                        };
                    })}
                />
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};

export default connect(mapStateToProps)(Project);
