// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "../assets/styles/homepage/sponsors.scss";

// Import utils
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

class Sponsors extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.sponsors?.length - 1 < itemCount,
        });

    }

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.sponsors?.length,
        });
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1200) return 5;
        else if (width > 900) return 4;
        else if (width > 600) return 3;
        else return 2;
    };

    render() {
        const {staticTexts, sponsors} = this.props;

        const settings = {
            dots: false,
            arrows: false,
            infinite:true,
            autoplay:true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            ref: sponsor => (this.sponsors = sponsor),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        rows:sponsors?.length > 6 ? 4 : 3,
                        autoplay:false,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 2,
                        rows:sponsors?.length > 6 ? 4 : 3,
                        autoplay:false,
                    }
                },
            ]
        };

        return !!sponsors?.length && <div className="sponsors-wrapper">
            <div className="sponsors-title">{staticTexts?.service_sponsors_title}</div>

            <div className="sponsors-list-wrapper">
                {
                    <Slider {...settings}>
                        {sponsors?.map(item => {
                            return <div  key={item}>
                                <div className="sponsors-item" key={item.id}>
                                    <div className="sponsors-content">
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                             title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                             alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                    </div>
                                </div>
                            </div>
                        })}
                    </Slider>
                }
            </div>
        </div>
    }
}

export default Sponsors;
