// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/homepage/contacts.scss'
import {LoadingOutlined} from '@ant-design/icons';

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {sendSubscribe} from "../redux/actions";
import isEmail from "validator/es/lib/isEmail";
import {InputGroup} from "./uiElements/inputGroup";
import {ConsultationCallIcon} from "../assets/images";

function Subscribe(props) {
    const [data, setData] = useState({
        email:'',
        phone:'',
    });
    const [error, setError] = useState({
        email:false,
        phone:false,
    });
    const {staticTexts,requestLoading,contacts} = props;

    const getInputValues = (e) => {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setData({
            ...data,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    };


    const sendSubscribe = () => {
        if (data.email && isEmail(data.email) && data.phone) {
            let reqDate = data.email
            props.sendSubscribe(reqDate).then(() => {
                setData(data)
            }, errorEmail => setError(!errorEmail))
        } else {
            setError({
                email: true,
                phone: true
            })
        }
    }
    useEffect(()=> {
        setData({
            email:'',
            phone:'',
        })
        setError({
            email:false,
            phone:false,
        })
    },[requestLoading])
    return <div className="contacts-wrapper">
            <div className={'contactsRedBg'}/>
            <div className={'contactsGrayBg'}/>
            <div className="form-content">
                <div className={'contacts-info-block'}>
                    <div className="title">
                        {staticTexts?.service_consultation_title}
                    </div>
                    <div className="description">
                        {staticTexts?.service_consultation_description}
                    </div>
                </div>
                <div className={'subscribe-contact-info'}>
                    <div className="input-wrapper">
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={data.email}
                            error={error.phone}
                            name={'email'}
                            placeholder={staticTexts?.contacts_page_placeholder_email}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={data.phone}
                            error={error.phone}
                            name={'phone'}
                            placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                            maxLength={100}
                            onChange={getInputValues}
                        />

                        <button className='sent-email'
                                onClick={() => {
                                    if (!requestLoading && sendSubscribe) {
                                        sendSubscribe()
                                    }
                                }}>
                            {staticTexts?.subscribe_btn}
                            {
                                requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                    : ''
                            }

                        </button>
                    </div>
                    {/*<div className={'subscribe-phones-wrapper'}>*/}
                    {/*    <a href={`tel:${contacts?.phoneNumber}`} className={'number'}>*/}
                    {/*        <ConsultationCallIcon/> <span>{contacts?.phoneNumber}</span>*/}
                    {/*    </a>*/}
                    {/*    <a href={`tel:${contacts?.phoneNumber}`} className={'number'}>*/}
                    {/*        <ConsultationCallIcon/> <span>{contacts?.phoneNumber}</span>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'contacts',
    ])
};

const mapDispatchToProps = {
    sendSubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);