export const GENERAL_CONSTS = {
    GET_STATIC_TEXTS: "GET_STATIC_TEXTS",
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    GET_CONTACTS: "GET_CONTACTS",
    GET_ABOUT: "GET_ABOUT",
    GET_SLIDER: "GET_SLIDER",
    GET_MENU: "GET_MENU",
    SEND_MESSAGE: "SEND_MESSAGE",
    SEND_SUBSCRIBERS: "SEND_SUBSCRIBERS",
    GET_SERVICES: "GET_SERVICES",
    GET_SERVICES_BLOCK: "GET_SERVICES_BLOCK",
    GET_FEATURES_BLOCK: "GET_FEATURES_BLOCK",
    GET_PARTNERS: "GET_PARTNERS",
    GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
    GET_PROJECT:"GET_PROJECT",
    GET_BLOCKS:'GET_BLOCKS',
    GET_SPONSORS:'GET_SPONSORS',
    GET_WORKS:'GET_WORKS',
};

