// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/work.scss'
import {CheckedIcon, PagLeftIcon, PagRightIcon} from "../assets/images";
import {Pagination} from "antd";

//Import components
import Subscribe from "../components/Contacts";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetWorks} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {PAGE_NEWS_COUNT} from "../constants/constType";


function Works(props){
    const {staticTexts,works} = props
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)

    useEffect(()=>{
        props.GetWorks()
    },[props.selectedLanguage?.code])

    async function onPageChanged (works) {
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: (works - 1) * PAGE_NEWS_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
        await props.GetWorks(configInfo)
        setCurrentPage(works);
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={'work-page'}>
        <div className={'work-page-wrapper'}>
            <div className={'work-main-block'}>
                <div className={'main-block-subtitle'}>
                    {staticTexts?.work_page_subTitle}
                </div>
                <div className={'main-block-title'}>
                    {staticTexts?.work_page_title}
                </div>
                <div className={'main-block-description'}>
                    {staticTexts?.work_page_description}
                </div>
            </div>

            {
                works?.itemsList?.length && <div className={'works-wrapper'}>
                    {
                        works?.itemsList.map((item,index) => {
                            return <div className={'work-block'} key={item?.id}>
                                <div className={'work-img'}>
                                    <div className={'img-wrapper'}>
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                                    </div>
                                </div>
                                <div className={'work-info'}>
                                    <div className={'info-title'}>
                                        {item?.title}
                                    </div>
                                    <div className={'info-description'}>
                                        {item?.description}
                                    </div>
                                    {
                                        item?.options.map((info,index) => {
                                            return <div className={'info-option'} key={index}>
                                                <div className={'icon'}>
                                                    <CheckedIcon/>
                                                </div>
                                                <span>{info}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            }
            <Pagination current={currentPage}
                        onChange={onPageChanged}
                        total={works?.count}
                        defaultPageSize={PAGE_NEWS_COUNT}
                        hideOnSinglePage={true}
                        itemRender={itemRender}
                        className={`pagination`}
            />
        </div>
        <Subscribe/>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'works',
    ])
};
const mapDispatchToProps = {
    GetWorks
};
export default connect(mapStateToProps,mapDispatchToProps)(Works);