// ----------------------------------4Steps-server---------------------------------------------------
// const BASE_URL =  "http://173.212.234.119:3100/api/v1";
// export const HOST_MEDIA_URL_V1 = "http://173.212.234.119:3100";


// //----------------------------------Official-server---------------------------------------------------
const BASE_URL =  "https://app.sossystems.am/front/api/v1";
export const HOST_MEDIA_URL_V1 = "https://app.sossystems.am/front";


export const _urlLanguage = BASE_URL + "/languages";
export const _urlStaticTexts = BASE_URL + "/static-texts";
export const _urlSlider = BASE_URL + "/slider";
export const _urlPartners = BASE_URL + "/partners";
export const _urlContacts = BASE_URL + "/contact";
export const _urlContacts2 = BASE_URL + "/contact/offer";
export const _urlContacts3 = BASE_URL + "/contact/proffer";
export const _urlAbout = BASE_URL + "/about";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlProjects = BASE_URL + "/projects";
export const _urlBlocks = BASE_URL + "/blocks";
export const _urlServices = BASE_URL + "/services";
export const _urlSponsors = BASE_URL + "/sponsors";
export const _urlWorks = BASE_URL + "/works";
export const _urlCountryCode1 = "http://ip-api.com/json";

