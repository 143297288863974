import {
    request,
    _urlStaticTexts,
    _urlSlider,
    _urlPartners,
    _urlContacts,
    _urlLanguage,
    _urlSubscribers,
    _urlAbout,
    _urlCountryCode1,
    _urlProjects, _urlBlocks, _urlContacts2, _urlServices, _urlSponsors, _urlWorks, _urlContacts3,
} from "../api";
import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};

const _GetCountryCode1 = () => {
    const requestData = {
        url: _urlCountryCode1,
        method: "GET",
    };
    return request(requestData)
        .then((res) => {
            const data = res && res.data;
            const code = data?.countryCode === 'AM' ? 'HY' : data?.countryCode
            return code?.toLowerCase() || 'HY'
        }).catch(_ => 'en')
};

export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const countryCode = await _GetCountryCode1()
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload:data
                })
            })
    };
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetSlider = () => {
    const requestData = {
        url: `${_urlSlider}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SLIDER,
                    payload: data
                });
            })
    };
};


export const GetPartners = () => {
    const requestData = {
        url: `${_urlPartners}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PARTNERS,
                    payload: data
                })
            })
    };
};

export const GetAbout = () => {
    const requestData = {
        url: `${_urlAbout}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_ABOUT,
                    payload: data
                })
            })
    };
};
export const GetServices = () => {
    const requestData = {
        url: `${_urlServices}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SERVICES,
                    payload: data
                })
            })
    };
};

export const GetContacts = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CONTACTS,
                    payload: data
                })
            })
    };
};

export const SendEmail = (data) => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
export const SendEmailOffer = (data) => {
    const requestData = {
        url: `${_urlContacts2}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
export const SendProffer = (data) => {
    const requestData = {
        url: `${_urlContacts3}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
export const sendSubscribe = (data) => {
    const requestData = {
        url: `${_urlSubscribers}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};

export const GetProjects = () => {
    const requestData = {
        url: `${_urlProjects}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PROJECT,
                    payload: data
                })
            })
    };
};
export const GetBlocks = (type) => {
    const requestData = {
        url: `${_urlBlocks}?type=${type}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BLOCKS,
                    payload: data
                })
            })
    };
};
export const GetBlocksServices = (type) => {
    const requestData = {
        url: `${_urlBlocks}?type=${type}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SERVICES_BLOCK,
                    payload: data
                })
            })
    };
};
export const GetBlocksFeatures = (type) => {
    const requestData = {
        url: `${_urlBlocks}?type=${type}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_FEATURES_BLOCK,
                    payload: data
                })
            })
    };
};
export const GetSponsors = (type) => {
    const requestData = {
        url: `${_urlSponsors}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SPONSORS,
                    payload: data
                })
            })
    };
};

export const GetWorks = ({
                             reset = true,
                             offset = 0,
                             category,
                             limit = 9,
                         } = {}) => {
    let url = `${_urlWorks}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_WORKS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

