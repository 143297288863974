// import packages
import React, {useEffect, useState} from "react";
import Slider from "react-slick";
//import assets
import "../../assets/styles/homepage/slider.scss";

// Import Components

// Import utils
import {SliderBackIcon, SliderGrayBackIcon, SliderLinkIcon} from "../../assets/images";
import {Link} from "react-router-dom";

function SliderBox(props) {
    const {news,staticTexts} = props
    const [state, setState] = useState({
        nav1: null,
        nav2: null
    })

    useEffect(() => {
        setState({
            nav1: state.slider1,
            nav2: state.slider2
        })
    }, [])

    const settings1 = {
        dots: false,
        infinite: true,
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        speed: 700,
        centerPadding: '60px 0 60px',
        asNavFor: state?.nav2,
        ref: slider => (state.slider1 = slider),
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    variableWidth:true,
                    centerPadding: '60px 0 0',
                    centerMode:false,
                }
            },
        ]
    };
    const settings2 = {
        dots: false,
        infinite: true,
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        speed: 700,
        centerPadding: '60px 0 60px',
        asNavFor: state?.nav1,
        ref: slider => (state.slider2 = slider),
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    variableWidth:true,
                    centerPadding: '60px 0 0',
                    centerMode:false,
                }
            },
        ]
    };
    return <div className={'slider-wrapper'}>
        <div className={'right-back'}>
            <SliderBackIcon/>
        </div>
        <div className={'left-back'}>
            <SliderGrayBackIcon/>
        </div>
        <div className={'slider'}>
            <div className={'slider-info'}>
                <div className={'slider-title'}>
                    {staticTexts?.homepage_slider_block_title}
                </div>
                <div className={'slider-description'}>
                    {staticTexts?.homepage_slider_block_description}
                </div>
                <Link to={'/about'} className={'slider-link'}>
                    <span>{staticTexts?.homepage_more_info_btn}</span> <SliderLinkIcon/>
                </Link>
            </div>

            <div className={'slider-blocks'}>
                {
                    <Slider {...settings1}>
                        {news.slice(0, news?.length / 2)?.map(item => {
                            return <div key={item} className={'news-wrapper'}>
                                {
                                    item?.color === '#FFFFFF' ? <div className="news-item" key={item.id}>
                                        {item?.title}
                                    </div> : <div className="news-item" key={item.id}
                                                  style={item?.color && {
                                                      background: item?.color,
                                                      color: "white",
                                                      borderColor: item?.color
                                                  }}>
                                        {item?.title}
                                    </div>
                                }
                            </div>
                        })}
                    </Slider>
                }
                {
                    <Slider {...settings2}>
                        {news.slice(news?.length / 2)?.map(item => {
                            return <div key={item} className={'news-wrapper'}>
                                {
                                    item?.color === '#FFFFFF' ? <div className="news-item" key={item.id}>
                                        {item?.title}
                                    </div> : <div className="news-item" key={item.id}
                                                  style={item?.color && {
                                                      background: item?.color,
                                                      color: "white",
                                                      borderColor: item?.color
                                                  }}>
                                        {item?.title}
                                    </div>
                                }
                            </div>
                        })}
                    </Slider>
                }
            </div>
        </div>
    </div>
}

export default SliderBox;
