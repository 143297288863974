// import packages
import React from "react";
import {connect} from "react-redux";

//Import styles
import "../../assets/styles/homepage/mainBlock.scss";

//Import Components
import {getPropsFromState} from "../../redux/mapStateToProps";
import {CALENDAR_LINK} from "../../constants/constType";

// Import utils


function MainBlock({staticTexts}){
    return <div className={'mainBlock'}>
        <div className={'info-block'}>
            <div className={"text"}>
                <div className={'title'}>
                    {staticTexts?.homepage_main_block_title}
                </div>
                {/*<div className={'description'}>*/}
                {/*    {staticTexts?.homepage_main_block_description}*/}
                {/*</div>*/}
                {/*<div className={'main-link'}>*/}
                {/*    {staticTexts?.homepage_main_block_btn}*/}
                {/*</div>*/}
            </div>

            <a className={'consultation'} href={CALENDAR_LINK} target={'_blank'}>
                {staticTexts?.homepage_consultation_btn_title}
            </a>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(MainBlock);
