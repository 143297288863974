import {GENERAL_CONSTS} from "../constants";
import { history } from "../../configs/history";

export const initialState = {
    languages: [],
    selectedLanguage: null,
    staticTexts: {},
    mainSlider: [],
    works: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    blocksList: [],
    singleBlog: null,
    blogPage: {},
    partners: [],
    services: [],
    servicesBlocks: [],
    featuresBlocks: [],
    sponsors: [],
    contacts: {},
    about: {},
    projects: {},
    countryCode: 'en',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);

            let newSelectedLanguage ='';
            const pathCode = history.location?.pathname?.split('/')?.[1]
            console.log('pathCode',pathCode);
            if(pathCode && action.payload.find(lg => lg.code === pathCode)){
                newSelectedLanguage = action.payload.find(lg => lg.code === pathCode)
            }else{
                const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                    && action.payload.find(lg => lg.id === state.selectedLanguage.id);
                newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                    (mainLanguage ? mainLanguage : action.payload[0]);
            }
            console.log('newSelectedLanguage', newSelectedLanguage)
            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };

            if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
                newState.translation = state.staticTexts[newSelectedLanguage.code]
            }
            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code || 'en']
            };
        case GENERAL_CONSTS.GET_COUNTRY_CODE:
            return {
                ...state,
                // staticTexts: ;
            };
        case GENERAL_CONSTS.GET_MENU:
            return {
                ...state,
                menuList: action.payload
            };
        case GENERAL_CONSTS.GET_WORKS:
            return {
                ...state,
                works: {
                    count: action.payload.data.worksCount,
                    itemsList:action.payload.reset ? action.payload.data.works : [...state.data.works, ...action.payload.data.works],
                    hasMore: action.payload.hasMore
                }
            };
        case GENERAL_CONSTS.GET_SLIDER:
            return {
                ...state,
                mainSlider: action.payload
            };
        case GENERAL_CONSTS.GET_SERVICES:
            return {
                ...state,
                services: action.payload
            };
        case GENERAL_CONSTS.GET_SERVICES_BLOCK:
            return {
                ...state,
                servicesBlocks: action.payload
            };
        case GENERAL_CONSTS.GET_FEATURES_BLOCK:
            return {
                ...state,
                featuresBlocks: action.payload
            };
        case GENERAL_CONSTS.GET_SPONSORS:
            return {
                ...state,
                sponsors: action.payload
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case GENERAL_CONSTS.GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        case GENERAL_CONSTS.GET_ABOUT:
            return {
                ...state,
                about: action.payload
            };
        case GENERAL_CONSTS.GET_PROJECT:
            return {
                ...state,
                projects: action.payload
            };
        case GENERAL_CONSTS.GET_BLOCKS:
            return {
                ...state,
                blocksList: action.payload,
            };
        default:
            return state;
    }
}
