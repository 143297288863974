// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/services.scss'

//Import components
import Sponsors from "../components/Sponsors";
import Consultation from "../components/Consultation";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetBlocksFeatures, GetServices, GetSponsors} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {BLOCKS_TYPE} from "../constants/constType";
import Subscribe from "../components/Contacts";
import {Link} from "react-router-dom";

function Services(props){
    const {staticTexts,services,sponsors,featuresBlocks} = props
    useEffect(() => {
        props.GetServices()
        props.GetSponsors()
        props.GetBlocksFeatures(BLOCKS_TYPE.FEATURES)
    },[props.selectedLanguage?.code])
   return <div className={'services-page-wrapper'}>
       <div className={'services-main-block'}>
           <div className={'services-main-info'}>
               <div className={'services-main-title'}>
                   {services?.title}
               </div>
               <div className={'services-main-description'}>
                   {services?.description}
               </div>
               <Link to={'/contacts'} className={'services-main-link'}>
                   {staticTexts?.footer_section_contact}
               </Link>
           </div>
           <div className={'services-main-img'}>
               <div className={'services-img-wrapper'}>
                   <img src={generateImageMediaUrl(services?.mediaMain?.path)}
                        title={services?.mediaMain?.name ? services?.mediaMain?.title : ''}
                        alt={services?.mediaMain?.name ? services?.mediaMain?.title : ''}/>
               </div>
           </div>
       </div>
       <Sponsors staticTexts={staticTexts} sponsors={sponsors}/>
       {
           services?.blocks && <div className={'services-block'}>

           <div className={'services-block-info'}>
               <div className={'services-block-title'}>
                   {staticTexts?.service_blocks_title}
               </div>
               <div className={'services-block-description'}>
                   {staticTexts?.service_blocks_description}
               </div>
           </div>
               <div className={'blocks-wrapper'}>
                   {
                       services?.blocks?.map((item,index) => {
                           return <div className={'blocks'} key={index}>
                               <div className={'blocks-text'}>
                                   <div className={'blocks-title'}>
                                       {item?.title}
                                   </div>
                                   <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                               </div>
                               <div className={'blocks-img'}>
                                   <div className={'img-wrapper'}>
                                       <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                            title={services?.mediaMain?.name ? services?.mediaMain?.title : ''}
                                            alt={services?.mediaMain?.name ? services?.mediaMain?.title : ''}/>
                                   </div>
                               </div>
                           </div>
                       })
                   }
               </div>
           </div>
       }

       {
           featuresBlocks?.length && <div className={'services-feature'}>
               <div className={'services-feature-title'}>
                   {staticTexts?.service_feathers_title}
               </div>
               <div className={'services-feature-description'}>
                   {staticTexts?.service_feathers_description}
               </div>
               <div className={'feature-wrapper'}>
                   {
                       featuresBlocks?.map((block,index) => {
                           return <div className={'features-block'} key={index}>
                               <div className={'features-block-icon'}>
                                   <img src={generateImageMediaUrl(block?.icon?.path)}
                                        title={block?.icon?.name ? block?.icon?.title : ''}
                                        alt={block?.icon?.name ? block?.icon?.title : ''}/>
                               </div>
                               <div className={'features-block-hoverIcon'}>
                                   <img src={generateImageMediaUrl(block?.hoverIcon?.path)}
                                        title={block?.hoverIcon?.name ? block?.hoverIcon?.title : ''}
                                        alt={block?.hoverIcon?.name ? block?.hoverIcon?.title : ''}/>
                               </div>
                               <div className={'features-block-title'}>
                                   {block?.title}
                               </div>
                               <div className={'features-block-description'}  dangerouslySetInnerHTML={{__html: block?.description}}/>

                           </div>
                       })
                   }
               </div>
           </div>
       }
       <Subscribe/>
   </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'services',
        'sponsors',
        'featuresBlocks'
    ])
};
const mapDispatchToProps = {
    GetServices,
    GetSponsors,
    GetBlocksFeatures
};
export default connect(mapStateToProps,mapDispatchToProps)(Services);