// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import styles
import "../../assets/styles/homepage/securityBlocks.scss";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {GetBlocks} from "../../redux/actions";
import {BLOCKS_TYPE} from "../../constants/constType";
import {useLocation} from "react-router-dom";


function SecurityBlocks(props){
    const {blocksList,staticTexts} = props
    const location = useLocation()

    /*todo 'SECURITY' պահել կոնստանտներում*/
    useEffect(()=>{
       props.GetBlocks(BLOCKS_TYPE.SECURITY);
    },[location.pathname])

    return <div className={'security-blocks'}>
        <div className={'security-title'}>
            {staticTexts?.homepage_security_block_title}
        </div>
        <div className={'block-wrapper'}>
            {blocksList?.map(item => {
                return <div className={'block'} key={item?.id}>
                    <div className={'icon-wrapper'}>
                        <img src={generateImageMediaUrl(item?.icon?.path)}
                             title={item?.icon?.name ? item?.icon?.title : ''}
                             alt={item?.icon?.name ? item?.icon?.title : ''}/>
                    </div>
                    <div className={'title'}>
                        {item?.title}
                    </div>
                    <div className={'description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                </div>
            })}
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'blocksList',
        'staticTexts',
    ])
};
const mapDispatchToProps = {
    GetBlocks,
};

export default connect(mapStateToProps,mapDispatchToProps)(SecurityBlocks);
