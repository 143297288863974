// import packages
import React from "react";
import {connect} from "react-redux";
import {Link, NavLink} from 'react-router-dom'

// Import assets
import '../assets/styles/components/footer.scss'
import {ReactComponent as Logo} from '../assets/images/Logo.svg';
import {CallIcon, FooterLogoIcon, SMSIcon} from "../assets/images";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetContacts} from "../redux/actions";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";


function Footer(props) {
    const {contacts, staticTexts,services} = props;
    // console.log(contacts,'contactscontacts')

    function downloadPresentation () {
        const file = contacts?.presentationFile;
        if (file) {
            fetch(generateImageMediaUrl(file?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = file?.name;
                        a.click();
                    });
                });
        }
    }

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                <div className={'border'}/>
                <div className="first-col">
                    <div className="logo-wrapper">
                       <Logo title={''}/>
                    </div>
                    <div className="icons-wrapper">
                       <a href={`tel:${contacts?.phoneNumber}`} className={"call-wrapper"}>
                           <CallIcon/> <span>{contacts?.phoneNumber}</span>
                       </a>
                        <a href={`tel:${contacts?.phoneNumber2}`} className={"call-wrapper"}>
                           <CallIcon/> <span>{contacts?.phoneNumber2}</span>
                       </a>
                        <a href={`mailto:${contacts?.email}`} className={"call-wrapper"}>
                            <SMSIcon/> <span>{contacts?.email}</span>
                        </a>
                    </div>
                </div>
                {contacts?.presentationFile && <div className={'download-presentation'} onClick={downloadPresentation}>
                    {staticTexts?.footer_download_presentation}
                </div>}
                <div className="col">
                    <div className="title">{staticTexts?.footer_section_sites_title}</div>
                    <NavLink to={getUrlWithLocal(`/home`)}>{staticTexts?.footer_section_home}</NavLink>
                    <NavLink to={getUrlWithLocal(`/about`)}>{staticTexts?.footer_section_about}</NavLink>
                    <NavLink to={getUrlWithLocal(`/work`)}>{staticTexts?.footer_section_news}</NavLink>
                    <NavLink to={getUrlWithLocal(`/partners`)}>{staticTexts?.footer_section_staff}</NavLink>
                    <NavLink to={getUrlWithLocal(`/services`)}>{staticTexts?.footer_section_services_title}</NavLink>
                    <NavLink to={getUrlWithLocal(`/contacts`)}>{staticTexts?.footer_section_contact}</NavLink>
                </div>
                <div className="col">
                    <div className="title">{staticTexts?.footer_section_services_title}</div>
                    {
                        !!services?.blocks?.length && services?.blocks.map(item => {
                            return <Link to={'/services'} className={'service'}>
                                {item.title}
                            </Link>
                        })
                    }

                </div>
                <div className="col">
                    <div className="title">{staticTexts?.footer_section_projects_title}</div>
                    <a href={`https://smartlife.am/`} target={'_blank'}>{staticTexts?.footer_section_MobileID}</a>
                    <a href={`https://sos911.am/`} target={'_blank'}>{staticTexts?.footer_section_Fortune}</a>
                </div>
            </div>

            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_section_copyright_text}</div>
                <div className='developed-by'>
                    <div className={'develop'}>
                        {staticTexts?.footer_section_developed_by_text}
                        <a href={"https://solidy.am/"} target={'_blank'} className={'footer-logo'}>
                            <FooterLogoIcon/>
                        </a>
                    </div>
                    <div className={'design'}>
                        {staticTexts?.footer_section_developed_by_text} <p>Ham’n’Jam</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'services',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {GetContacts};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
