// import packages
import React, {useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/components/contactsBlock.scss'
import {LoadingOutlined} from '@ant-design/icons';

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {SendEmail} from "../redux/actions";
import isEmail from "validator/es/lib/isEmail";
import {InputGroup} from "./uiElements/inputGroup";


function ContactsBlock(props) {
    const {staticTexts, requestLoading} = props
    const [fieldsData, setFieldsData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
    })

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5)) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendEmail() {
        if (validate()) {
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                })
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    return <div className={'contactsBlock-wrapper'}>
        <div className={'contactsBlock-block'}>
                <div className={'contact-main-wrapper'}>
                    <div className={'contact-main-subtitle'}>
                        {staticTexts?.partners_page_contact_subTitle}
                    </div>
                        <div className={'contact-main-title'}>
                            {staticTexts?.partners_page_contact_title}
                        </div>
                        <div className={'contact-main-description'}>
                            {staticTexts?.partners_page_contact_description}
                        </div>
                </div>
                <div className={'inputs-wrapper'}>
                    <div className={'fullName-wrapper'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.firstName}
                            error={error.firstName}
                            name={'firstName'}
                            placeholder={staticTexts?.contacts_page_placeholder_name}
                            label={staticTexts?.contacts_page_label_name}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.lastName}
                            error={error.lastName}
                            name={'lastName'}
                            placeholder={staticTexts?.contacts_page_placeholder_lastName}
                            label={staticTexts?.contacts_page_label_lastName}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.email}
                        error={error.email}
                        name={'email'}
                        placeholder={staticTexts?.contacts_page_placeholder_email}
                        label={staticTexts?.contacts_page_label_email}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.phone}
                        error={error.phone}
                        name={'phone'}
                        placeholder={staticTexts?.contacts_page_label_phoneNumber}
                        label={staticTexts?.contacts_page_label_phoneNumber}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <button className='sent-email'
                            onClick={() => {
                                if (!requestLoading && sendEmail) {
                                    sendEmail()
                                }
                            }}>
                        {staticTexts?.partners_page_contact_btn}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : ''
                        }

                    </button>
                </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsBlock);