// import packages
import React from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/components/consultation.scss'

//Import components

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {ConsultationCallIcon} from "../assets/images";


function Consultation(props){
    const{contacts,staticTexts}=props

    return <div className={'consultation-wrapper'}>
        <div className={'leftBg'}/>
        <div className={'rightBg'}/>
        <div className={'consultation-block'}>
            <div className={'consultation-info'}>
                <div className={'consultation-title'}>
                    {staticTexts?.service_consultation_title}
                </div>
                <div className={'consultation-description'}>
                    {staticTexts?.service_consultation_description}
                </div>
            </div>
            <div className={'consultation-number'}>
                <a href={`tel:${contacts?.phoneNumber}`} className={'number'}>
                    <ConsultationCallIcon/> <span>{contacts?.phoneNumber}</span>
                </a>
            </div>
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Consultation);