// import packages
import React from "react";
import {useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../../assets/styles/homepage/contactOffer.scss'
import {LoadingOutlined} from "@ant-design/icons";
import {ContactImg, DownloadIcon} from "../../assets/images";

// Import Components
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmailOffer} from "../../redux/actions";
import isEmail from "validator/es/lib/isEmail";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import ContactModal from "./ContactModal";

function ContactOffer(props) {
    const {staticTexts, contacts} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [fieldsData, setFieldsData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
    })

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5)) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendEmail() {
        if (validate()) {
            props.SendEmailOffer(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                })
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    function downloadFile(pdf) {
        // console.log(pdf,'pdf')
        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = 'file';
                        a.click();
                    });
                });
        }
    }
    function toggleModalOPen(){
        setModalOpen(!modalOpen)
    }

    return <div className={'contact2-wrapper'}>
            <div className={'redBg'}/>
            <div className={'grayBg'}/>
        <div className={'contact2-block'}>
            <div className={'subtitle'}>
                {staticTexts?.homepage_contact2_subtitle}
            </div>
            <div className={'contact-main-block'}>
                <div className={'main-wrapper'}>
                    <div className={'contact-main-info'}>
                        <div className={'contact-main-title'}>
                            {staticTexts?.homepage_contact2_title}
                        </div>
                        <div className={'contact-main-description'}>
                            {staticTexts?.homepage_contact2_description}
                        </div>
                    </div>
                    <div className={'contact-download-wrapper'}>
                        <div className={'download-title'}>
                            {staticTexts?.homepage_contact2_download_title}
                        </div>
                        <div className={'download-block'} onClick={() => downloadFile(contacts?.pdf)}>
                            <DownloadIcon/> <span>{staticTexts?.homepage_contact2_download_info}</span>
                        </div>
                        <button className={'contact-main-btn'} onClick={toggleModalOPen}>
                            {staticTexts?.homepage_contact2_button_send}
                        </button>
                    </div>
                </div>
               <div className={'contact2-text-wrapper'}>
                   {staticTexts?.contact2_text}
               </div>
            </div>
        </div>
        <ContactModal modalOpen={modalOpen} toggleModalOpen={toggleModalOPen}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmailOffer,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactOffer);
