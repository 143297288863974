// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/partners.scss'

//Import components
import Consultation from "../components/Consultation";
import ContactsBlock from "../components/ContactsBlock";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetPartners} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import Subscribe from "../components/Contacts";


function Partners(props) {
    const {partners, staticTexts} = props

    useEffect(() => {
        props.GetPartners()
    }, [props.selectedLanguage?.code])

    return <div className={'partners-page-wrapper'}>
        <div className={'partners-page-info'}>
            <div className={'partners-page-title'}>
                {staticTexts?.partners_page_title}
            </div>
            <div className={'partners-page-description'}>
                {staticTexts?.partners_page_description}
            </div>
        </div>
        {
            partners?.length && <div className={'partners'}>
                <div className={'partners-title'}>
                    {staticTexts?.partners_page_subTitle}
                </div>
            <div className={'partners-component'}>
                {
                    partners.map(partner => {
                        return <a href={partner.link} target={'_blank'} className={'partners-block'} key={partner?.id}>
                            <div className={'partners-img-wrapper'}>
                                <div className={'img-block'}>
                                    <img src={generateImageMediaUrl(partner?.mediaMain?.path)}
                                         title={partner?.mediaMain?.title ? partner?.mediaMain?.title : ''}
                                         alt={partner?.mediaMain?.altAttribute ? partner?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                            <div className={'partners-block-title'}>
                                {partner?.title}
                            </div>
                        </a>
                    })
                }
            </div>
            </div>
        }
        <ContactsBlock/>
        <Subscribe/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'partners',
    ])
};
const mapDispatchToProps = {
    GetPartners,
};
export default connect(mapStateToProps, mapDispatchToProps)(Partners);