// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/about.scss'

//Import components
import Subscribe from "../components/Contacts";
import Sponsors from "../components/Sponsors";
import SystemsBlock from "../components/SystemsBlock";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {GetAbout} from "../redux/actions";


function About(props) {
    const {about,staticTexts,sponsors} = props

    useEffect(() => {
        props.GetAbout()
    },[props.selectedLanguage?.code])
    return <div className={'about-wrapper'}>
        <div className={'about-main-wrapper'}>
            <div className={'about-main'}>
                <div className={'about-main-title'}>
                    {about?.title}
                </div>
                <div className={'about-main-description'}>
                    {about?.description}
                </div>
            </div>
        </div>
        <Sponsors staticTexts={staticTexts} sponsors={sponsors}/>

        {about?.blocks?.length && <div className={'blocks-wrapper'}>
            {about?.blocks.map((item,index) => {
                return <div className={'blocks'} key={index}>
                    <div className={'blocks-img'}>
                        <div className={'img-wrapper'}>
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                        </div>
                    </div>
                    <div className={'blocks-text'}>
                        <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                    </div>
                </div>
            })}
        </div>}
        <SystemsBlock staticTexts={staticTexts}/>
        <Subscribe/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
        'staticTexts',
        'sponsors',
    ])
};
const mapDispatchToProps = {
    GetAbout,
};

export default connect(mapStateToProps,mapDispatchToProps)(About);