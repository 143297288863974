// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import Components;
import Partners from "../components/Partners";
import MainBlock from "../components/homepage/MainBlock";
import Project from "../components/homepage/Project";
import SliderBox from "../components/homepage/SliderBox";
import SecurityBlocks from "../components/homepage/SecurityBlocks";
import ContactOffer from "../components/homepage/ContactOffer";
import ServicesBlocks from "../components/homepage/ServicesBlocks";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";


class Homepage extends Component {
    render() {
        const { partners, staticTexts,contacts,projects,mainSlider,} = this.props;
        return <div className="homepage-wrapper">
            <MainBlock staticTexts={staticTexts} contacts={contacts}/>
            <SliderBox news={mainSlider} staticTexts={staticTexts}/>
            <SecurityBlocks staticTexts={staticTexts}/>
            <ContactOffer staticTexts={staticTexts}/>
            <Project projects={projects} staticTexts={staticTexts}/>
            <Partners partners={partners} staticTexts={staticTexts}/>
            <ServicesBlocks staticTexts={staticTexts}/>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'partners',
        'staticTexts',
        'contacts',
        'projects',
        'mainSlider',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
