// import packages
import React from "react";
import {connect} from "react-redux";
import {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

//Import styles
import "../../assets/styles/homepage/services.scss";
import {ServicesAttentionIcon, SliderLinkIcon} from "../../assets/images";

//Import Components
import {getPropsFromState} from "../../redux/mapStateToProps";
import { GetBlocksServices} from "../../redux/actions";
import {BLOCKS_TYPE, CALENDAR_LINK} from "../../constants/constType";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";


function ServicesBlocks(props){
    const {servicesBlocks,staticTexts} = props
    const location = useLocation()
    /*todo 'SERVICES' պահել կոնստանտներում*/
    useEffect(()=>{
        props.GetBlocksServices(BLOCKS_TYPE.SERVICES);
    },[location.pathname])
    return <div className={'services'}>
        <div className={'header'}>
            <div className={'subtitle'}>{staticTexts?.homepage_services_subtitle}</div>
            <div className={'header-text'}>
                <div className={'title'}>{staticTexts?.homepage_services_title}</div>
                <div className={'link-wrapper'}>
                    <div className={'description'}>{staticTexts?.homepage_services_description}</div>
                    <Link to={'/services'} className={'project-more-news'}>
                        {staticTexts?.homepage_more_info_btn} <SliderLinkIcon/>
                    </Link>
                </div>
            </div>
        </div>
        <div className={'services-wrapper'}>
            {servicesBlocks?.map(item => {
                return <div className={'service-block'} key={item?.id}>
                    <div className={'service-icon-wrapper'}>
                        <img src={generateImageMediaUrl(item?.icon?.path)}
                             title={item?.icon?.name ? item?.icon?.title : ''}
                             alt={item?.icon?.name ? item?.icon?.title : ''}/>
                    </div>
                    <div className={'hover-icon-wrapper'}>
                        <img src={generateImageMediaUrl(item?.hoverIcon?.path)}
                             title={item?.hoverIcon?.name ? item?.hoverIcon?.title : ''}
                             alt={item?.hoverIcon?.name ? item?.hoverIcon?.title : ''}/>
                    </div>
                    <div className={'service-title'}>
                        {item?.title}
                    </div>
                    <div className={'service-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                </div>
            })}
        </div>
        <div className={'services-more-info'}>
            <div className={'info'}>
                <p>
                    <ServicesAttentionIcon/>
                </p>
                <span>
                   {staticTexts?.homepage_services_attention}
                </span>
            </div>
            <Link to={getUrlWithLocal('/services')} className={'all-info-btn'} >
                {staticTexts?.homepage_services_btn}
            </Link>
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'servicesBlocks',
        'staticTexts',
    ])
};
const mapDispatchToProps = {
    GetBlocksServices,
};

export default connect(mapStateToProps,mapDispatchToProps)(ServicesBlocks);
